/* eslint-disable @typescript-eslint/space-before-function-paren */
/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import React, { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { useOktaAuth } from '@okta/okta-react'
import { connect, useDispatch, useSelector } from 'react-redux'
import ButtonStateActions, { toggleSumbit } from '../../../Store/Actions/ButtonStateActions'
import { fetchTestsAndIcdCodes, resetSelectedTest, toggleClinicalInfoValid, updatePhysicianSignCheck, updateSelectedTest } from '../../../Store/Actions/OnlineOrderFormActions'
import toastMessages from '../../../utils/helpers/toastMessage'
import Button from '../../atomic/Button'
import CustomImage from '../../global/CustomImage'
import CustomInput from '../../global/CustomInput'
import TestSelectorLoadingIndicator from '../../global/LoadingIndicators/CreateOnlineOrderIndicators/TestSelectorLoadingIndicator'
import { InView } from 'react-intersection-observer'
import scrollHelper from '../../../utils/helpers/scrollHelper'
import validationHelper from '../../../utils/helpers/orderFormValidationHelper'
import http from '../../../Store/API/HttpMethods'
import CustomPopup from '../../global/CustomPopup'
import NotificationActions from '../../../Store/Actions/NotificationsActions'
import TestCard from './TestCard'
import dataConstants from '../../../constants/dataConstants'


function TestSection({ setFieldValue, gettingTests, errors, COAtests, values, touched, allowedTestsCOA, setFieldError, setValidSections, setSelectedTab, validateForm, testsAndIcdCodes, profile, isEdit, ...rest }: any): JSX.Element {
  const selectedTest = values?.selectedTest
  const history = useHistory()
  const dispatch = useDispatch()
  const isCOA = profile?.roles?.includes('Collaborative Order Agreement')
  const { selectedPhysician } = useSelector(
    (state: any) => state.OnlineOrderForm
  )
  const { state }: any = useLocation()
  const [showPopup, setPopup] = useState<boolean>(false)
  function enableSubmit() {
    dispatch(toggleSumbit(true));
  }
  function disableSubmit() {
    dispatch(toggleSumbit(false));
  }

  const setMultipleKeyValues = (ResetKeys: Object) => {
    const updatedValues = { ...values }
    Object.keys(ResetKeys).forEach((key) => {
      // To access Object key's
      type ObjectKey = keyof typeof ResetKeys
      const myKey = key as ObjectKey

      updatedValues[key] = ResetKeys[myKey]
      setFieldValue(key, ResetKeys[myKey])
    })
    return updatedValues
  }

  function resetClinicalInfo(id: number): void {
    const ResetKeys = {
      question1: '',
      question2: '',
      question3: '',
      isSentinelLymphNodeBiopsy: '',
      isClinicallyPalpableNodes: '',
      isPrimaryCutaneousMelanocyticNeoplasm: '',
      isIntrasitMetastasis: '',
      isSpitzoidFeatures: '',
      lymphNodeStatus: '',
      isExposedToRadiation: '',
      isLesionJunctional: '',
      isCastleStandardTesting: false,
      isDDMNotReported: false,
      isMyPathNotReported: false,
      history_physical_exam: [],
      pathology_surgical_findings: [],
      isAltheaDxNeuropsychotropicMedications: { options: [] },
      isIDgOTCHerbalMedsEnvironmentalFactorDietarySupplement: [],
      isIDgNeuropsychotropicMedications: [],
      isIDgNeuropsychotropicMedicationsCurrent: [],
      other: ''
    }

    setMultipleKeyValues(ResetKeys)
    dispatch(toggleSumbit(false))
    setValidSections((prevState: any) => prevState.filter((item: string) => item !== 'clinical info'))

    if (id === 3 || id === 2 || id === 5 || id === 8 || id === 4) {
      dispatch(toggleClinicalInfoValid(false))
    } else {
      dispatch(toggleClinicalInfoValid(true))
    }
  }

  function resetLabInfoValues(id: number) {
    if (id == 8 && !(profile.roles.includes('PathLab User') || profile.roles.includes('Collaborative Order Agreement'))) {
      const ResetKeys = {
        name_of_facility: '',
        facility_contact_person: '',
        lab_phone: '',
        lab_fax: '',
        tubeBarcode: '',
        pathologist: '',
        tumorSite: '',
        // workingDiagnosis: '',
        facilityType: '',
        isTeleHealth: false
      }

      validationHelper(
        validateForm,
        setValidSections,
        setMultipleKeyValues(ResetKeys),
        {
          name_of_facility: "name",
          date_of_collection: "",
          facility_contact_person: "",
          lab_fax: "fax",
          lab_phone: "phone",
          isTeleHealth: false,
          tubeBarcode: "",
          pathologist: '',
          tumorSite: '',
          // workingDiagnosis: '',
        },
        "laboratory info",
        enableSubmit,
        disableSubmit
      );
    }

  }
  function handleChange(id: number, checkedIndex: number): void {
    if (!selectedTest) {
      const data = { id, checkedIndexes: [checkedIndex] }

      validateForm(values).then((res: any) => {
        if (Object.keys(res).length === 0) {
          dispatch(toggleSumbit(true))
        } else {
          dispatch(toggleSumbit(false))
        }
      }).catch((error: any) => {
        // console.log(error)
      })

      dispatch(updateSelectedTest(data))
      setFieldValue('selectedTest', data)
      setValidSections((prevState: any) => [...prevState, 'select test'])
      rest?.setTouchedSections((prevState: any) => [...prevState, 'select test'])
      return
    }

    if (selectedTest.id !== id) {
      resetClinicalInfo(id)
      const data = { id, checkedIndexes: [checkedIndex] }
      dispatch(updateSelectedTest(data))
      setFieldValue('selectedTest', data)
      setFieldValue('medicare', '')
      setValidSections((prevState: any) => [...prevState, 'select test'])
      rest?.setTouchedSections((prevState: any) => [...prevState, 'select test'])
      setFieldValue("isPhysicianSignPermission", false);
      dispatch(updatePhysicianSignCheck(false));
      if (id !== 4) {
        setFieldValue('patient_ssn', '')
        setFieldValue('patient_mrn', '')
      }

      resetLabInfoValues(id)

      if (id === 2 || id === 3 || id === 5) {
        dispatch(ButtonStateActions.toggleSumbit(false))
      } else if (id !== 8) {
        setFieldValue('isTeleHealth', false)
        setFieldValue("tubeBarcode", '');
      } else {
        validateForm(values).then((res: any): void => {
          if (Object.keys(res).length === 0) {
            dispatch(toggleSumbit(true))
          } else {
            dispatch(toggleSumbit(false))
          }
        }).catch((error: any) => {
          // console.log(error)
        })
      }
    } else {
      const temp = { ...selectedTest }
      if (!temp.checkedIndexes.includes(checkedIndex)) {
        temp.checkedIndexes.push(checkedIndex)
      } else {
        const index = temp.checkedIndexes.indexOf(checkedIndex)
        if (index !== -1) {
          temp.checkedIndexes.splice(index, 1)
        }
      }
      if (temp.checkedIndexes.length === 0) {
        dispatch(resetSelectedTest())
        setFieldValue('selectedTest', null)
        dispatch(toggleSumbit(false))
        setValidSections((prevState: any) => prevState.filter((item: string) => item !== 'select test'))
      } else {
        setValidSections((prevState: any) => [...prevState, 'select test'])
        rest?.setTouchedSections((prevState: any) => [...prevState, 'select test'])
        setFieldValue('selectedTest', temp)
        dispatch(updateSelectedTest(temp))

        validateForm(values).then((res: any): void => {
          if (Object.keys(res).length === 0) {
            dispatch(toggleSumbit(true))
          } else {
            dispatch(toggleSumbit(false))
          }
        }).catch((error: any) => {
          // console.log(error)
        })
      }
    }
  }

  function handleMouseScroll(inView: boolean) {
    scrollHelper.handleInView(inView, setSelectedTab, false, 'select test')
  }
  useEffect(() => {
    if ((selectedTest && isEdit) || (state?.orderValue && selectedTest?.id !== undefined)) {
      setValidSections((prevState: any) => [...prevState, 'select test'])
      rest?.setTouchedSections?.((prevState: any) => [...prevState, 'select test'])
      dispatch(updateSelectedTest(selectedTest))
    }

  }, [selectedTest?.id])

  return <InView id='select test' as={'div'} className={'scroll-mt-64'} threshold={0.5} onChange={handleMouseScroll}>

    <div className="flex flex-col">
      <div className="w-full border my-3">
        {
          testsAndIcdCodes.filter((test: any) => {
            if (isCOA && profile?.id !== selectedPhysician?.id) {
              return test?.tests?.find((data: any) => allowedTestsCOA?.includes(data?.code))
            }
            return true
          }).map((testData: any) => (<TestCard
            key={testData.disease}
            {...testData}
            touched={touched}
            errors={errors}
            isCOA={isCOA}
            allowedTestsCOA={allowedTestsCOA}
            handleChange={handleChange}
          />))
        }
      </div>
      <Button
        btnType="primary"
        title={'Ok'}
        disabled={selectedTest ? selectedTest.checkedIndexes.length === 0 : true}
        // disabled={selectedTest?  selectedTest.checkedIndexes.length == 0 ??: false}
        className={'self-end'}
        onClick={() => {
          setValidSections((prevState: any) => [...prevState, 'select test'])
          rest?.setTouchedSections((prevState: string[]) => [...prevState, 'select test'])
          const section: any = document.getElementById('patient info')
          section.scrollIntoView({ behavior: 'smooth', block: 'start' })
          setSelectedTab('patient info')
        }} />
    </div>
  </InView>
}

function SelectTestForm({ initialValues, values, allowedTestsCOA, gettingTests, setFieldValue, errors, touched, setFieldError, profile, setValidSections, setSelectedTab, validateForm, fetchTestsAndIcdCodes, loading, error, testsAndIcdCodes, isEdit, ...rest }: any): JSX.Element {

  const { authState } = useOktaAuth()
  const { selectedPhysician, selectedOrganization, selectedPathLab } = useSelector(
    (state: any) => state.OnlineOrderForm
  );
  const isCOA = profile?.roles?.includes('Collaborative Order Agreement')
  const dispatch = useDispatch()
  useEffect(() => {
    if (selectedOrganization?.id) {
      const options = {
        orgId: selectedOrganization?.id ?? '00g697s5s34SqUjzX5d7',
        config: {
          headers: {
            Authorization: `Bearer ${authState?.accessToken?.accessToken}`
          }
        },
        onError: (message: string) => toastMessages.error(message),
        onSingleTest: (test: any) => {
          setValidSections((prevState: any) => [...prevState, 'select test'])
          setFieldValue('selectedTest', test)
          dispatch(updateSelectedTest(test))
        }
      }
      if (!loading || error === true) {
        fetchTestsAndIcdCodes(options)
      }
    }
  }, [selectedOrganization?.id])

  if (isCOA ? gettingTests : loading) {
    return <TestSelectorLoadingIndicator setSelectedTab={setSelectedTab} />
  }

  return <div className="pb-14">
    <TestSection
      setFieldValue={setFieldValue}
      COAtests={allowedTestsCOA}
      errors={errors}
      touched={touched}
      values={values}
      isEdit={isEdit}
      setFieldError={setFieldError}
      setValidSections={setValidSections}
      setSelectedTab={setSelectedTab}
      validateForm={validateForm}
      allowedTestsCOA={allowedTestsCOA}
      testsAndIcdCodes={testsAndIcdCodes}
      gettingTests={gettingTests}
      profile={profile}
      {...rest}
    />
  </div>
}

const mapStateToProps = (state: any): any => ({
  loading: state.OnlineOrderForm.fetchingTestsAndIcdCodes,
  error: state.OnlineOrderForm.fetchingTestsAndIcdCodesError,
  testsAndIcdCodes: state.OnlineOrderForm.testsAndIcdCodes,
  profile: state.Profile.profile
})
const mapDispatchToProps = {
  fetchTestsAndIcdCodes: fetchTestsAndIcdCodes
}

export default connect(mapStateToProps, mapDispatchToProps)(SelectTestForm)
